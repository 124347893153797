import React, { Component } from 'react'
import { connect } from 'react-redux'
import { graphql } from 'gatsby'
import Standard from '@/shared/Standard'
import { setupPage } from '@/utils/common'

// import * as R from 'ramda'

class TermsAndConditionsPage extends Component {
	constructor(props) {
		super(props)

		setupPage(props)
	}

	render() {
		const {
			data: {
				craft: { entry }
			},
			location
		} = this.props
		return <Standard entry={entry} location={location} />
	}
}
const mapState = state => ({
	...state.player
})

const mapDispatch = ({ player: { setPageType, setAudioBarVisibility } }) => ({
	setPageType,
	setAudioBarVisibility
})

export default connect(
	mapState,
	mapDispatch
)(TermsAndConditionsPage)

export const pageQuery = graphql`
	query TermsAndConditionsQuery {
		craft {
			entry(id: 7) {
				... on Craft_LandingPagesStandard {
					title
					uri
					body {
						content
					}

					seoTitle
					seoDescription
					socialMetaTitle
					socialMetaDescription
					socialMetaImage {
						... on Craft_ImagesVolume {
							optimisedImage {
								... on Craft_OptimizedImagesData {
									src
								}
							}
						}
					}
				}
			}
		}
	}
`

/*


*/
